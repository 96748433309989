<template>
	<div>
		<!-- Facebook Callback Page -->
		<h3>Please wait while we are setting up your Facebook account...</h3>
		<v-skeleton-loader type="image,image,image"></v-skeleton-loader>
	</div>
</template>

<script>
export default {
	created() {
		this.$store.dispatch('socialMedia/facebook/storeAccount', {
			payload: this.$route.query,
		})
	},
}
</script>
